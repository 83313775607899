import useErrorMessageHandler from '@/@core/helpers/errorMessageHandler';
import { useCallApi } from '@/@core/services/axiosCall';
import {
  reactive,
  Ref,
  ref,
  toRefs,
  unref
} from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useNoteStore = defineStore('note', () => {
  const state = reactive({
    total: 0,
    notes: []
  });

  async function getManyNote() {
    const { errorMessage, result, error } = await useCallApi({
      method: 'get',
      url: '/notes'
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    state.notes = result.notes;
    state.total = result.count;

    return result;
  }

  async function postNote(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'post',
      url: '/notes',
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  async function putNote(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'put',
      url: '/notes/' + data.id,
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  async function deleteNote(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'delete',
      url: '/notes/' + data.id
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }
  return {
    ...toRefs(state),
    getManyNote,
    postNote,
    deleteNote,
    putNote
  };
});
